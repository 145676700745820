import axios, { InternalAxiosRequestConfig, AxiosResponse } from 'axios';
import {
  getGxTokenFromStorage,
  getTripTokenFromStorage,
  setTripTokenToStorage
} from 'utils/commonFunctions';

interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
  _retry?: boolean;
}

const MAX_RETRY_ATTEMPTS = 3;

let refreshTokenPromise: Promise<string | null> | null = null;

const baseURL = import.meta.env.VITE_REACT_APP_API_ENDPOINT;
const dataURL = import.meta.env.VITE_GX_DATA_ENDPOINT;
const gxURL = import.meta.env.VITE_GX_API_ENDPOINT;
const internalURL = import.meta.env.VITE_GX_INTERNAL_ENDPOINT;

const axiosGoInstance = axios.create({ baseURL });

const axiosDataInstance = axios.create({ baseURL: dataURL });

const axiosGxInstance = axios.create({ baseURL: gxURL });

const axiosInternalInstance = axios.create({ baseURL: internalURL });
const gxGoToken = import.meta.env.VITE_GX_GO_TOKEN;
const instances = [axiosGoInstance, axiosDataInstance, axiosInternalInstance, axiosGxInstance];
instances.forEach(instance => {
  instance.interceptors.request.use((config: CustomAxiosRequestConfig) => {
    const tripToken = getTripTokenFromStorage();
    if (tripToken && config.headers) {
      config.headers.Authorization = `Bearer ${tripToken}`;
      if (!config.url?.startsWith('/auth/apikey')) {
        config.headers.set('x-gx-go-token', gxGoToken);
      }
    }
    return config;
  });

  instance.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    async error => {
      const originalRequest = error.config as CustomAxiosRequestConfig;

      if (error.response?.status === 401 && !originalRequest._retry) {
        if (!refreshTokenPromise) {
          refreshTokenPromise = getNewTripToken()
            .then(newToken => {
              refreshTokenPromise = null;
              if (newToken) {
                setTripTokenToStorage(newToken);
                return newToken;
              }
              return null;
            })
            .catch(() => {
              refreshTokenPromise = null;
              return null;
            });
        }

        return refreshTokenPromise.then(newToken => {
          if (newToken) {
            originalRequest.headers.Authorization = `Bearer ${newToken}`;
            originalRequest._retry = true;
            return axiosGoInstance(originalRequest);
          }
          return Promise.reject(error);
        });
      }
      return Promise.reject(error);
    }
  );
});

const getNewTripToken = async (): Promise<string | null> => {
  const gxToken = getGxTokenFromStorage();
  if (!gxToken) {
    console.error('No token found for refresh.');
    window.location.href = '/login';
    return null;
  }

  let retryAttempts = 0;
  let newToken: string | null = null;

  while (retryAttempts < MAX_RETRY_ATTEMPTS) {
    try {
      const url = `/token?token=${encodeURIComponent(gxToken)}`;
      const response = await axiosGoInstance.get(url);

      if (response.data && response.data.token) {
        newToken = response.data.token;
        console.log('Received new token.');
        setTripTokenToStorage(newToken as string);
        break;
      }
    } catch (error) {
      console.error('Failed to refresh token on attempt', retryAttempts + 1, ':', error);
    }

    retryAttempts += 1;
  }

  if (!newToken) {
    console.error('Token refresh failed after maximum retries.');
    window.location.href = '/logout';
  }

  return newToken;
};

const httpInstances = {
  axiosGoInstance,
  axiosDataInstance,
  axiosInternalInstance,
  axiosGxInstance
};

export default httpInstances;
