import { UserConfig } from './../../../slices/userConfigSlice';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useTokenGeneration from '../../Profile/TokenGeneration/useTokenGeneration';
import { RootState } from 'store';
import httpInstances from 'api/axiosInstance';
import { transformPickerDateToISO } from 'utils/commonFunctions';

let timerId: any = null;

const useLaunchpadExport = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [exported, setExported] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [from, setFrom] = useState<Date | null>(null);
  const [to, setTo] = useState<Date | null>(null);
  const [periodNumber, setPeriodNumber] = useState<string>('2');
  const [period, setPeriod] = useState<string>('m');
  const [exportType, setExportType] = useState<'all' | 'range' | 'dates'>('all');
  const [includeMetadata, setIncludeMetadata] = useState<boolean>(false);
  const [previewOptionId, setPreviewOptionId] = useState<string>('');

  const { dataHubs, activeDataHubId } = useSelector((state: RootState) => state.dataHub);
  const launchpadSymbols = dataHubs.find(dataHub => dataHub.id === activeDataHubId)?.symbols || [];
  const { apiKeys } = useTokenGeneration();

  const { userConfig } = useSelector((state: RootState) => state.userConfig);
  const userTimezone = userConfig.timezone.value;

  useEffect(() => {
    clearTimeout(timerId);
  }, [previewOptionId]);

  const gxURL = import.meta.env.VITE_GX_API_ENDPOINT;

  const getExportBody = () => ({
    symbols: launchpadSymbols.map(symbol => {
      return {
        group: 'Prod_Indexes',
        key: {
          code: symbol.Code,
          periodType: symbol.PeriodType,
          timeRef: symbol.TimeRef,
          period: symbol.Period
        }
      };
    })
  });

  const getFromToValues = (
    exportType: 'all' | 'range' | 'dates',
    from?: Date | null,
    to?: Date | null
  ) => {
    let fromValue: string = '';
    let toValue: string = '';

    switch (exportType) {
      case 'all':
        fromValue = 'all';
        break;
      case 'range':
        fromValue = `${periodNumber}${period}`;
        break;
      case 'dates':
        fromValue = transformPickerDateToISO(from, 'start');
        toValue = transformPickerDateToISO(to, 'end');
        break;
      default:
        throw new Error('Invalid export type specified');
    }

    return { fromValue, toValue };
  };

  const { fromValue, toValue } = getFromToValues(exportType, from, to);

  const exportData = async (type: string) => {
    setLoading(true);
    try {
      const endpoint = `export/${type.startsWith('excel') ? type : `third-party?type=${type}`}&from=${fromValue}${toValue ? `&to=${toValue}` : ''}&metadata=${includeMetadata}`;
      await httpInstances.axiosGoInstance.post(endpoint, getExportBody());
      setExported(true);
      if (!type.startsWith('excel')) {
        timerId = setTimeout(() => {
          setExported(false);
          setPreviewOptionId('');
        }, 5000);
      }
    } catch (error) {
      console.error(error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const uniqueSymbolCodes = launchpadSymbols.map(symbol => symbol.Code).join(',');
  const sortedKeys = [...apiKeys]?.sort(
    (a, b) => new Date(a.creationDate).getTime() - new Date(b.creationDate).getTime()
  );
  const apiKey = sortedKeys?.slice(-1)[0]?.prefixedRawApiKey ?? '<YourApiKeyHere>';
  const exportApiRequestString = `${gxURL}/index?code=${uniqueSymbolCodes}&from=${fromValue}${toValue ? `&to=${toValue}` : ''}&metadata=${includeMetadata}&timezone=${userTimezone}&token=${apiKey}`;
  const exportCurlRequestString = `curl --location "${gxURL}/index?code=${uniqueSymbolCodes}&from=${fromValue}${toValue ? `&to=${toValue}` : ''}&timezone=${userTimezone}&metadata=${includeMetadata}" --header "Accept: application/json" --header "Authorization: ApiKey ${apiKey}"`;

  return {
    loading,
    exported,
    setExported,
    exportData,
    exportApiRequestString,
    exportCurlRequestString,
    includeMetadata,
    setIncludeMetadata,
    exportType,
    setExportType,
    from,
    setFrom,
    to,
    setTo,
    period,
    setPeriod,
    periodNumber,
    setPeriodNumber,
    previewOptionId,
    setPreviewOptionId
  };
};

export default useLaunchpadExport;
