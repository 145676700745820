import { Box, Button, ListItem, Typography } from '@mui/material';
import { DeleteOutline } from '@mui/icons-material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { DataHub, setActiveDataHubId } from 'slices/dataHubSlice';
import useDataHubSelector from 'pages/Launchpad/useDataHubSelector';
import { estimateLastSaved } from 'utils/commonFunctions';
import Dialog from 'components/Dialog';
import { useAppDispatch } from 'hooks/redux-hooks';

const LaunchpadDropdownItem = ({
  dataHub,
  setActiveTab
}: {
  dataHub: DataHub;
  setActiveTab: (index: number) => void;
}) => {
  const dispatch = useAppDispatch();

  const [confirmClearDialogOpen, setConfirmClearDialogOpen] = useState(false);

  const { deleteDataHub } = useDataHubSelector();
  const { activeDataHubId } = useSelector((state: RootState) => state.dataHub);
  const isActive = dataHub.id === activeDataHubId;

  return (
    <ListItem
      sx={{
        m: 0.5,
        pl: 1,
        pr: 0,
        cursor: 'pointer',
        backgroundColor: isActive ? '#e84e0f29' : '#f8f8f81A',
        width: 'auto',
        position: 'relative'
      }}
      onClick={() => dispatch(setActiveDataHubId(dataHub.id))}
    >
      {isActive && (
        <Box
          sx={{
            position: 'absolute',
            width: 5,
            height: 5,
            backgroundColor: 'primary.main',
            borderRadius: '50%',
            right: 10
          }}
        />
      )}
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'flex-start'}
      >
        <Typography
          variant='caption'
          sx={{ fontWeight: 500 }}
        >
          {dataHub.name}
        </Typography>
        <Typography
          variant='caption'
          sx={{ fontSize: 10, color: 'info.main' }}
        >
          {`${dataHub.symbols.length} items, last saved ${estimateLastSaved(dataHub.savedAt)}`}
        </Typography>
        <Button
          variant='outlined'
          sx={{
            mt: 1,
            p: 0.2,
            backgroundColor: '#f8f8f833',
            border: 'none',
            fontSize: 10
          }}
          onClick={e => {
            e.stopPropagation();
            setConfirmClearDialogOpen(true);
          }}
        >
          <DeleteOutline sx={{ fontSize: '12px!important', m: '0!important' }} />
          Delete
        </Button>
      </Box>
      <Dialog
        text={
          <>
            Delete Data Hub?
            <br />
            <br />
            You will permanently lose this saved Data Hub.
          </>
        }
        actionButtonText='Delete Data Hub'
        hasCancelButton
        open={confirmClearDialogOpen}
        handleClose={() => setConfirmClearDialogOpen(false)}
        handleAction={() => {
          deleteDataHub(dataHub.id);
          setActiveTab(0);
          setConfirmClearDialogOpen(false);
          setTimeout(() => dispatch(setActiveDataHubId('')), 100);
        }}
      />
    </ListItem>
  );
};

export default LaunchpadDropdownItem;
