import { Box, List, SxProps, Theme } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import LaunchpadDropdownItem from './LaunchpadDropdownItem';
import Popper from 'components/Popper';
import { DataHub } from 'slices/dataHubSlice';

const LaunchpadsDropdown = ({
  setActiveTab,
  buttonContent,
  buttonVariant,
  buttonStyles
}: {
  setActiveTab: (index: number) => void;
  buttonContent: JSX.Element;
  buttonVariant?: 'text' | 'outlined' | 'contained' | undefined;
  buttonStyles?: SxProps<Theme>;
}) => {
  const { dataHubs } = useSelector((state: RootState) => state.dataHub);

  return (
    <Popper
      disabled={!dataHubs?.length}
      placement='bottom-start'
      buttonVariant={buttonVariant}
      buttonContent={buttonContent}
      buttonStyles={buttonStyles}
    >
      <Box
        sx={{
          width: 184,
          borderBottom: '1px solid',
          borderLeft: '1px solid',
          borderColor: 'primary.main',
          display: 'flex',
          flexDirection: 'column',
          fontSize: '14px',
          maxHeight: 424,
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: '8px'
          },
          '&::-webkit-scrollbar-track': {
            my: 0.5
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '12px',
            backgroundColor: '#6e729c',
            backgroundClip: 'content-box'
          }
        }}
      >
        <List disablePadding>
          {[...dataHubs]
            .sort((a, b) => new Date(b.savedAt).getTime() - new Date(a.savedAt).getTime())
            .map((dataHub: DataHub) => (
              <LaunchpadDropdownItem
                key={dataHub.id}
                dataHub={dataHub}
                setActiveTab={setActiveTab}
              />
            ))}
        </List>
      </Box>
    </Popper>
  );
};

export default LaunchpadsDropdown;
