import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { ChartSeries } from '../../useChartSeries';
import FullScreenLegend from './FullScreenLegend';
import useFullScreenChart from './useFullScreenChart';
import Loader from 'components/Loader';
import { ChartParentComponent } from 'pages/Insights/InsightsComponents/ChartsBlock/useChartFunctions';

const FullScreenChart = ({
  chartSeries,
  error,
  loading,
  isLaunchpad,
  parent
}: {
  chartSeries: ChartSeries[] | undefined;
  error: boolean;
  loading: boolean;
  isLaunchpad?: boolean;
  parent: ChartParentComponent;
}) => {
  const { chartObject, options, chartComponentRef } = useFullScreenChart({
    chartSeries,
    isLaunchpad,
    parent
  });

  const [, setChartHeight] = useState(window.innerHeight / 2);

  useEffect(() => {
    const handleResize = () => {
      const newHeight = window.innerHeight / 2;
      setChartHeight(newHeight);
      if (chartObject) {
        chartObject.setSize(null, newHeight);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (error)
    return (
      <Box
        sx={{
          width: '100%',
          height: '90vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <Typography color='info'>
          We couldn't retrieve your data. Please try again later.
        </Typography>
      </Box>
    );

  if (loading)
    return (
      <Box
        sx={{
          width: '100%',
          height: '90vh'
        }}
      >
        <Loader />
      </Box>
    );

  return (
    <Box
      className='chart-container'
      sx={{ mr: 2, backgroundColor: 'background.paper', position: 'relative ' }}
    >
      {' '}
      <Box className='stock-chart-fullscreeen'>
        <HighchartsReact
          highcharts={Highcharts}
          constructorType={'stockChart'}
          options={options}
          ref={chartComponentRef}
        />
      </Box>
      {chartObject?.series && <FullScreenLegend chart={chartObject} />}
    </Box>
  );
};
export default FullScreenChart;
