import { UserMetadata } from 'slices/authSlice';
import {
  GX_TOKEN_STORAGE_ATTRIBUTE_NAME,
  TRIP_TOKEN_STORAGE_ATTRIBUTE_NAME
} from 'utils/constants';
const baseURL: string = import.meta.env.VITE_REACT_APP_API_ENDPOINT;

export const detectEnvironment = (): 'local' | 'dev' | 'staging' | 'prod' => {
  if (window.location.hostname.includes('localhost')) {
    return 'local';
  }
  return baseURL.includes('stage') ? 'staging' : baseURL.includes('dev') ? 'dev' : 'prod';
};
export const getTripTokenFromStorage = () =>
  sessionStorage.getItem(TRIP_TOKEN_STORAGE_ATTRIBUTE_NAME);

export const setTripTokenToStorage = (tripToken: string): void => {
  sessionStorage.setItem(TRIP_TOKEN_STORAGE_ATTRIBUTE_NAME, tripToken);
};

export const getGxTokenFromStorage = () => localStorage.getItem(GX_TOKEN_STORAGE_ATTRIBUTE_NAME);

export const setGxTokenToStorage = (gxToken: string): void => {
  localStorage.setItem(GX_TOKEN_STORAGE_ATTRIBUTE_NAME, gxToken);
};

export const clearStoragesFromTokensAndUserInfo = async () => {
  await sessionStorage.removeItem(TRIP_TOKEN_STORAGE_ATTRIBUTE_NAME);
  await localStorage.removeItem(GX_TOKEN_STORAGE_ATTRIBUTE_NAME);
};

export const getDiffColor = (value: number | null) => {
  if (!value) return '#4F5458';
  if (value === 0) return '#4F5458';
  return Math.sign(value) > 0 ? '#00E676' : '#FF1744';
};

export const generateUUID = (): string =>
  '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c: string) =>
    (
      parseInt(c) ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (parseInt(c) / 4)))
    ).toString(16)
  );

export const debounce = (func: (...args: any[]) => void, delay: number) => {
  let timeout: NodeJS.Timeout;
  return (...args: unknown[]) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export const downloadAsCsv = (values: string[]) => {
  const csv = values.join('\n');
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const anchorElemnt = document.createElement('a');
  anchorElemnt.setAttribute('href', url);
  anchorElemnt.setAttribute('download', 'key.csv');
  anchorElemnt.click();
};

export const transformCamelCaseToSpaces = (text: string) => {
  return text.replace(/([a-z])([A-Z])/g, '$1 $2');
};

export const transformPickerDateToISO = (date: Date | null | undefined, time: 'start' | 'end') => {
  if (!date) return '';
  if (date.toString() === 'Invalid Date') return 'invalid_date';

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return time === 'start' ? `${year}-${month}-${day}T00:00:00` : `${year}-${month}-${day}T23:59:59`;
};

export const sleep = async (ms: number): Promise<void> =>
  new Promise(resolve => setTimeout(resolve, ms));

export const formatNumberWithPrecision = (cellValue: any) => {
  if (cellValue === undefined || cellValue === null) {
    return '';
  }
  const numberValue = +cellValue;
  if (isNaN(numberValue)) {
    return '';
  }
  const decimalPlaces = cellValue.includes('.') ? cellValue.split('.')[1].length : 0;
  return numberValue.toLocaleString('en-US', {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces
  });
};

export const retrieveFromUserMetadata = (userMetadata: UserMetadata[], key: string) => {
  const optionalValue = userMetadata.find(
    metdata => metdata.metadataKey.toLowerCase() === key.toLowerCase()
  );
  return optionalValue ? optionalValue.metadataValue : null;
};
export const estimateLastSaved = (savedTime: string) => {
  try {
    const justNow = 'just now';
    const ago = ' ago';

    const inputDate = new Date(Date.parse(savedTime));
    if (isNaN(inputDate.getTime())) {
      return 'Wrong date format';
    }

    const now = new Date();
    const diffInMilliseconds = now.getTime() - inputDate.getTime();

    const absDiff = Math.abs(diffInMilliseconds);

    const minutes = Math.floor(absDiff / (1000 * 60)) % 60;
    const hours = Math.floor(absDiff / (1000 * 60 * 60)) % 24;
    const days = Math.floor(absDiff / (1000 * 60 * 60 * 24));

    if (days > 0) return days + 'd' + ago;
    if (hours > 0) return hours + 'h' + ago;
    if (minutes > 0) return minutes + 'm' + ago;

    return justNow;
  } catch (error) {
    return `Error: ${error.message}`;
  }
};
