import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  SxProps
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ReactNode } from 'react';

const CustomDialog = ({
  open,
  handleClose,
  text,
  actionButtonText = 'Ok',
  handleAction,
  hasCrossCloseButton,
  hasCancelButton,
  cancelButtonText = 'Cancel',
  sx
}: {
  handleClose: () => void;
  open: boolean;
  text: ReactNode;
  actionButtonText?: ReactNode;
  handleAction: () => void;
  hasCrossCloseButton?: boolean;
  hasCancelButton?: boolean;
  cancelButtonText?: string;
  sx?: SxProps;
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{ backgroundColor: '#2634c866', ...sx }}
    >
      {hasCrossCloseButton && (
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'info.dark'
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
      <DialogContent sx={{ backgroundColor: 'background.default', minWidth: 500, pr: 5 }}>
        <DialogContentText sx={{ color: 'text.primary' }}>{text}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ backgroundColor: 'background.default', p: 3, pt: 1 }}>
        {hasCancelButton && (
          <Button
            sx={{ mr: 1, minWidth: 100 }}
            onClick={handleClose}
          >
            {cancelButtonText}
          </Button>
        )}
        <Button
          sx={{ minWidth: 100 }}
          variant='contained'
          onClick={handleAction}
        >
          {actionButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
