import { InputAdornment, TextField } from '@mui/material';
import { Search } from '@mui/icons-material';
import { KeyboardEvent, useRef } from 'react';

const TableSearch = ({
  searchValue,
  handleChangeSearch
}: {
  searchValue: string;
  handleChangeSearch: (value: string) => void;
}) => {
  const inputRef = useRef<HTMLInputElement>();

  return (
    <TextField
      inputRef={inputRef}
      hiddenLabel
      value={searchValue}
      onChange={e => handleChangeSearch(e.target.value)}
      onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') handleChangeSearch(searchValue);
      }}
      sx={{
        minWidth: 200,
        '& .MuiFilledInput-input': {
          p: 1.5
        },
        '& .MuiInputBase-root': {
          backgroundColor: 'secondary.main',
          height: 48,
          maxHeight: 48
        }
      }}
      slotProps={{
        input: {
          startAdornment: (
            <InputAdornment
              onClick={() => {
                if (inputRef.current) inputRef.current.focus();
              }}
              sx={{ m: 0 }}
              position='start'
            >
              <Search color='info' />
            </InputAdornment>
          )
        }
      }}
      variant='filled'
      placeholder='Search'
    />
  );
};

export default TableSearch;
