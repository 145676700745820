import { Box, Paper } from '@mui/material';
import { useState } from 'react';
import { CommoditiesTableTabType } from '../types';
import CommoditiesTableTab from './CommoditiesTableTab';
import Tabs from 'components/Tabs';
import TabPanel from 'components/TabPanel';

const CommoditiesTable = ({
  tabs,
  marketTitle,
  pageTitle,
  layout
}: {
  tabs: CommoditiesTableTabType[];
  marketTitle: string;
  pageTitle: string;
  layout?: { fullWidth?: boolean; height?: number };
}) => {
  const [activeTab, setActiveTab] = useState<number>(0);

  const { fullWidth, height } = layout || {};

  return (
    <Box
      component={Paper}
      sx={theme => ({
        width: fullWidth ? '100%' : 'calc(50% - 12px)',
        [theme.breakpoints.down(1350)]: {
          width: '100%'
        }
      })}
    >
      <Box sx={{ height: 48, mb: 1.8 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Tabs
            mainPageTitle={marketTitle}
            subPageTitle={pageTitle}
            height={48}
            tabs={tabs.map(({ name }) => ({ name }))}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </Box>
      </Box>
      {tabs.map(({ name, sections }, index) => (
        <TabPanel
          key={name}
          index={index}
          value={activeTab}
        >
          <CommoditiesTableTab
            sections={sections}
            height={height}
          />
        </TabPanel>
      ))}
    </Box>
  );
};

export default CommoditiesTable;
