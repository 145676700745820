import { useState } from 'react';
import { Box, ButtonBase } from '@mui/material';
import LinesEllipsis from 'react-lines-ellipsis';
import { DeleteOutlined } from '@mui/icons-material';
import PreviewTooltipContent from '../PreviewTooltipContent';
import Tooltip from 'components/Tooltip';
import { stockChartPalette } from 'utils/constants';
import Dialog from 'components/Dialog';
import useDataHubSelector from 'pages/Launchpad/useDataHubSelector';

const ColumnHeader = ({
  columnKey,
  index,
  isSummary
}: {
  columnKey: string;
  index: number;
  isSummary?: boolean;
}) => {
  const [timeRef, period, periodType, code, title] = columnKey.split('-');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { deleteSymbolFromDataHub } = useDataHubSelector();

  const handleDeleteSymbol = () => {
    deleteSymbolFromDataHub(columnKey);
    setIsModalOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Tooltip
          variant='secondary'
          title={<PreviewTooltipContent {...{ timeRef, period, periodType, code }} />}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
              userSelect: 'none'
            }}
          >
            <LinesEllipsis
              text={title}
              maxLine='2'
              ellipsis='...'
              trimRight
              basedOn='letters'
            />
            <ButtonBase onClick={() => setIsModalOpen(true)}>
              <DeleteOutlined sx={{ fontSize: 21 }} />
            </ButtonBase>
          </Box>
        </Tooltip>
        <Box
          sx={{
            height: index < 4 && isSummary ? 4 : 2,
            mt: 0.5,
            width: '100%',
            backgroundColor: index < 4 && isSummary ? stockChartPalette[index] : 'text.primary'
          }}
        />
      </Box>
      <Dialog
        text={
          <>
            Remove item?
            <br />
            <br />
            This item will be removed from the Data Hub
          </>
        }
        actionButtonText='Remove item'
        hasCancelButton
        open={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        handleAction={handleDeleteSymbol}
      />
    </>
  );
};

export default ColumnHeader;
