import { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { DisplaySettings, ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  ButtonBase,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';
import { Table } from '@tanstack/react-table';
import Popper from 'components/Popper';
import Checkbox from 'components/Checkbox';
import {
  allColumnIdsToChangeVisibility,
  displayedSettingsColumnsList
} from 'pages/Catalog/constants';
import { CatalogItem } from 'slices/catalogSlice';

const buttonBaseStyle = { color: 'primary.main', px: 0.2 };

const DisplaySettingsPopper = ({
  expandedData,
  setExpandedData,
  table,
  isForwardCurves
}: {
  expandedData: boolean;
  isForwardCurves: boolean;
  setExpandedData: (newValue: boolean) => void;
  table: Table<CatalogItem>;
}) => {
  const tanStackColumns = table.getAllColumns();
  const [showDiscontinued, setShowDiscontinued] = useState<boolean>(false);
  const handleChangeExpandedData = (e: ChangeEvent<HTMLInputElement>) => {
    if (isForwardCurves) return;
    setExpandedData(e.target.value === '1');
    const columnIdsToChangeVisibility = ['TimeRef', 'PeriodType', 'Period'];
    const columns = tanStackColumns.filter(column =>
      columnIdsToChangeVisibility.includes(column.id)
    );

    columns.forEach(column => {
      column.toggleVisibility();
    });
  };

  useEffect(() => {
    table.getColumn('Status')?.setFilterValue([showDiscontinued]);
  }, [showDiscontinued]);
  const listedColumns = tanStackColumns.filter(column =>
    allColumnIdsToChangeVisibility.includes(column.id)
  );

  const allListedColumnsSelected = listedColumns.every(column => column.getIsVisible());

  const handleSelectClearAll = () => {
    listedColumns.forEach(column => {
      column.toggleVisibility(!allListedColumnsSelected);
    });
  };

  return (
    <Popper
      placement='bottom-end'
      buttonVariant='outlined'
      buttonContent={
        <>
          <DisplaySettings sx={{ mr: 1 }} />
          Display settings
          <ExpandMore sx={{ ml: 6 }} />
        </>
      }
    >
      <Box
        sx={{
          minWidth: 280,
          borderBottom: '1px solid',
          borderLeft: '1px solid',
          borderColor: 'primary.main',
          display: 'flex',
          flexDirection: 'column',
          fontSize: '14px',
          minHeight: 200
        }}
      >
        <Accordion
          defaultExpanded={!isForwardCurves}
          disabled={isForwardCurves}
        >
          <AccordionSummary
            sx={{
              '&.Mui-expanded': {
                backgroundColor: 'secondary.main'
              }
            }}
            expandIcon={<ExpandMore />}
          >
            Expanded Spot Catalog
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: 'secondary.main' }}>
            <FormControl>
              <RadioGroup
                aria-labelledby='demo-radio-buttons-group-label'
                value={expandedData ? '1' : '0'}
                onChange={handleChangeExpandedData}
              >
                <FormControlLabel
                  value='1'
                  control={<Radio />}
                  label='Display all items for each code'
                />
                <Typography
                  color='info'
                  component='div'
                  sx={{ fontSize: '12px', fontStyle: 'italic', mb: 0.5 }}
                >
                  Shows TimeRef, PeriodType, Period columns
                </Typography>
                <FormControlLabel
                  value='0'
                  control={<Radio />}
                  label='Display one item per code'
                />
              </RadioGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion>
        <FormControlLabel
          sx={{ m: 0, py: 1, px: 2 }}
          control={
            <Checkbox
              checked={showDiscontinued}
              onChange={() => setShowDiscontinued(!showDiscontinued)}
            />
          }
          label='Show discontinued items'
        />
        <Accordion>
          <AccordionSummary
            sx={{
              '&.Mui-expanded': {
                backgroundColor: 'secondary.main'
              }
            }}
            expandIcon={<ExpandMore />}
          >
            Additional Metadata columns
          </AccordionSummary>
          <AccordionDetails
            sx={{
              backgroundColor: 'secondary.main',
              pb: 0
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box>
                {displayedSettingsColumnsList.map(({ label, columns }) => (
                  <Fragment key={label}>
                    <Typography
                      color='info'
                      component='div'
                      sx={{ fontSize: '10px', textTransform: 'uppercase' }}
                    >
                      {label}
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', pb: 1 }}>
                      {columns.map(({ displayLabel, accessorKey }) => {
                        const tanStackColumn = tanStackColumns.find(
                          ({ id }: { id: string }) => id === accessorKey
                        );
                        return (
                          <FormControlLabel
                            key={accessorKey}
                            sx={{ m: 0, py: 0.1 }}
                            control={
                              <Checkbox
                                checked={tanStackColumn?.getIsVisible()}
                                onChange={tanStackColumn?.getToggleVisibilityHandler()}
                              />
                            }
                            label={displayLabel}
                          />
                        );
                      })}
                    </Box>
                  </Fragment>
                ))}
              </Box>
              <Box>
                <ButtonBase
                  sx={buttonBaseStyle}
                  onClick={handleSelectClearAll}
                >
                  {allListedColumnsSelected ? 'Clear all' : 'Select all'}
                </ButtonBase>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Popper>
  );
};

export default DisplaySettingsPopper;
