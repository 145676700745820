import { Row } from '@tanstack/react-table';
import { useTheme } from '@mui/material';
import { VirtualItem } from '@tanstack/react-virtual';
import { CatalogItem } from 'slices/catalogSlice';
import useSelectionFunctions from 'components/SymbolSelector/useSelectionFunctions';
import { SelectedSymbol } from 'slices/symbolSelectorSlice';
import ForwardCatalogCell from 'pages/Catalog/CatalogTable/CatalogTableRow/ForwardCatalogCell';
import SpotCatalogCell from 'pages/Catalog/CatalogTable/CatalogTableRow/SpotCatalogCell';

const CatalogTableRow = ({
  highlightedRowIndex,
  setHighlightedRowIndex,
  virtualRow,
  row,
  isForwardCurves
}: {
  highlightedRowIndex: number | null;
  setHighlightedRowIndex: (index: number | null) => void;
  virtualRow: VirtualItem;
  row: Row<CatalogItem>;
  isForwardCurves: boolean;
}) => {
  const { isSymbolSelected } = useSelectionFunctions();
  const { palette } = useTheme();

  const checkIsRestricted = () => {
    if (isForwardCurves) return false;
    const {
      original: { Last, Change }
    } = row;
    return !Last && !Change;
  };
  const isRestrictedData = checkIsRestricted();

  const isHighlighted = virtualRow.index === highlightedRowIndex;

  const symbol: SelectedSymbol = row.getValue('Symbol');

  const createForwardCurveSymbols = () => {
    const selectedSymbols: SelectedSymbol[] = [];
    const periods: number = row.getValue('Periods');
    if (isForwardCurves && periods) {
      for (let i = 0; i < periods; i++) {
        selectedSymbols.push({
          Code: symbol.Code,
          TimeRef: symbol.TimeRef,
          Period: i.toString(),
          PeriodType: symbol.PeriodType
        });
      }
    }
    return selectedSymbols;
  };

  const symbols: SelectedSymbol[] = (isForwardCurves && createForwardCurveSymbols()) || [];

  const returnRowBgColor = () => {
    if (isForwardCurves) {
      if (symbols.some(symbol => isSymbolSelected(symbol))) return palette.table.forwardCurve;
    } else {
      if (isSymbolSelected(symbol)) return palette.table.spot;
    }
    if (isHighlighted) return palette.table.highlight;
    if (isRestrictedData) return palette.table.infoRowBg;
    if (virtualRow.index % 2 === 0) return palette.secondary.main;
    if (virtualRow.index % 2 !== 0) return palette.background.paper;
  };

  const handleRowClick = (e: any) => {
    if (e.target.type !== 'checkbox')
      setHighlightedRowIndex(isHighlighted ? null : virtualRow.index);
  };

  return (
    <div
      data-index={virtualRow.index}
      key={row.id + virtualRow.index}
      style={{
        display: 'flex',
        position: 'absolute',
        transform: `translateY(${virtualRow.start}px)`,
        width: '100%',
        height: '23px',
        padding: 0,
        paddingLeft: '8px',
        backgroundColor: returnRowBgColor()
      }}
      onClick={handleRowClick}
    >
      {row.getVisibleCells().map(cell => {
        return isForwardCurves ? (
          <ForwardCatalogCell
            key={row.id + cell.id}
            cell={cell}
            symbols={symbols}
          />
        ) : (
          <SpotCatalogCell
            key={row.id + cell.id}
            cell={cell}
            isRestrictedData={isRestrictedData}
            symbol={symbol}
          />
        );
      })}
    </div>
  );
};

export default CatalogTableRow;
