import { Box, Button, Link, List, ListItem, ListItemText, Paper, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import posthog from 'posthog-js';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import JsonCsvPreviewScreen from './JsonCsvPreviewScreen';
import CodeSnippetPreviewScreen from './CodeSnippetPreviewScreen';
import { exportOptionsGroups } from 'pages/Launchpad/Export/exportList';
import useUserDetails from 'hooks/useUserAccess';
import UpgradeToPremiumButton from 'pages/Launchpad/UpgradeToPremiumButton';
import TokenLinkComponent from 'pages/Launchpad/Export/Preview/TokenLinkComponent';

const ExportPreview = ({
  previewOptionId,
  exportApiRequestString,
  exportCurlRequestString,
  exportData,
  includeMetadata,
  from,
  to,
  period,
  periodNumber,
  exportType
}: {
  previewOptionId: string;
  exportApiRequestString: string;
  exportCurlRequestString: string;
  exportData: (type: string) => Promise<void>;
  includeMetadata: boolean;
  exportType: 'all' | 'range' | 'dates';
  from?: Date | null;
  to?: Date | null;
  period?: string;
  periodNumber?: string;
}) => {
  const { checkUserHasPremium } = useUserDetails();
  const { dataHubs, activeDataHubId } = useSelector((state: RootState) => state.dataHub);
  const launchpadSymbols = dataHubs.find(dataHub => dataHub.id === activeDataHubId)?.symbols || [];

  const findOptionById = () => {
    for (const group of exportOptionsGroups) {
      const foundOption = group.options.find(({ id }) => id === previewOptionId);
      if (foundOption) {
        return { ...foundOption, groupName: group.groupName };
      }
    }
    return null;
  };

  const { id, actionHeading, previewOptions, previewImg, groupName } = findOptionById() || {};

  const copyToClipboard = () => {
    navigator.clipboard.writeText(exportApiRequestString);
    enqueueSnackbar('Code snippet copied to clipboard!', {
      variant: 'success',
      autoHideDuration: 5000
    });
  };

  const handlePrimaryButtonClick = () => {
    capturePosthogEvent();
    if (groupName === 'Developer tools') copyToClipboard();
    else exportData(previewOptionId);
  };

  const returnPrimaryButtonLabel = () => {
    if (id === 'csv' || id === 'json' || id === 'python') return null;

    switch (groupName) {
      case 'File exports':
        return 'Download';
      case 'Developer tools':
        return 'Copy to clipboard';
      case 'Cloud analytics platforms':
      case 'BI tools':
      case 'Market data integrations':
      default:
        return 'Enable access';
    }
  };

  const capturePosthogEvent = () => {
    if (returnPrimaryButtonLabel() === 'Enable access') {
      posthog.capture('datahub_enable_access_button_click', {
        button: id
      });
      return;
    }
    const posthogData: any = {
      symbols: launchpadSymbols,
      exportName: id,
      dateSettings: exportType,
      includeMetadata: includeMetadata
    };

    if (exportType === 'dates') {
      posthogData.from = from?.toLocaleString();
      posthogData.to = to?.toLocaleString();
    } else if (exportType === 'range') {
      posthogData.period = period;
      posthogData.periodNumber = periodNumber;
    }

    posthog.capture('datahub_export_button_click', posthogData);
  };

  return (
    <Paper
      sx={{
        ml: 2,
        mt: 2,
        height: 708,
        flexGrow: 1,
        width: '35%',
        minWidth: 470,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'secondary.main',
        overflow: 'hidden'
      }}
    >
      {id ? (
        <>
          <Box>
            <Box sx={{ p: 2 }}>
              <Typography fontSize={24}>{actionHeading}</Typography>
              <List>
                {previewOptions?.map((option: string) => (
                  <ListItem
                    sx={{ p: 0 }}
                    key={option}
                  >
                    <Box sx={{ backgroundColor: 'text.primary', width: 4, height: 4, mr: 1 }} />
                    <ListItemText primary={option} />
                  </ListItem>
                ))}
                {(id === 'json' || id === 'gx_api') && <TokenLinkComponent />}
              </List>
              {returnPrimaryButtonLabel() && (
                <Box sx={{ mt: 2 }}>
                  {checkUserHasPremium() ? (
                    <Button
                      variant='contained'
                      onClick={handlePrimaryButtonClick}
                    >
                      {returnPrimaryButtonLabel()}
                    </Button>
                  ) : (
                    <UpgradeToPremiumButton />
                  )}
                  {id === 'gx_api' && checkUserHasPremium() && (
                    <Button
                      component={Link}
                      target='_blank'
                      rel='noopener noreferrer'
                      variant='contained'
                      href={exportApiRequestString}
                      onClick={capturePosthogEvent}
                      sx={{ ml: 2 }}
                    >
                      Launch
                    </Button>
                  )}
                </Box>
              )}
            </Box>
            {(id === 'json' || id === 'csv') && (
              <JsonCsvPreviewScreen
                isCSV={id === 'csv'}
                exportType={exportType}
                includeMetadata={includeMetadata}
                exportCurlRequestString={exportCurlRequestString}
                from={from}
                to={to}
                period={period}
                periodNumber={periodNumber}
                capturePosthogEvent={capturePosthogEvent}
              />
            )}
            {id === 'python' && (
              <CodeSnippetPreviewScreen
                exportType={exportType}
                includeMetadata={includeMetadata}
                from={from}
                to={to}
                period={period}
                periodNumber={periodNumber}
                capturePosthogEvent={capturePosthogEvent}
              />
            )}
            {id === 'gx_api' && checkUserHasPremium() && (
              <>
                <Paper
                  sx={{
                    m: 2,
                    p: 3,
                    backgroundColor: '#1A224D',
                    fontFamily: 'monospace',
                    wordBreak: 'break-all'
                  }}
                >
                  {exportApiRequestString}
                </Paper>
              </>
            )}
            {id === 'gx_api' && (
              <Typography sx={{ p: 2, fontSize: '12px' }}>
                For more information on the GX API, please visit our{' '}
                <Link
                  href='https://docs.g-x.co/#introduction'
                  target='_blank'
                  rel='noopener noreferrer'
                  underline='hover'
                >
                  documentation site.
                </Link>
              </Typography>
            )}
          </Box>
          {previewImg && (
            <Box
              component='img'
              src={previewImg}
              alt={actionHeading}
              sx={{ height: `100%` }}
            />
          )}
        </>
      ) : (
        <Box
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
        >
          Please select a download option
        </Box>
      )}
    </Paper>
  );
};

export default ExportPreview;
