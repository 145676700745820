import { Box, Button, Typography, Icon, styled } from '@mui/material';
import Draggable from 'react-draggable';
import {
  BookmarkAddOutlined,
  DeleteOutlined,
  DragIndicator,
  ListAltOutlined
} from '@mui/icons-material';
import { useRef } from 'react';
import LimitDialog from './LimitDialog';
import useSymbolSelector from './useSymbolSelector';
import DataHubsDropdown from './DataHubsDropdown';
import { clearSelection } from 'slices/symbolSelectorSlice';
import CustomCheckbox from 'components/Checkbox';
import { SHOW_SELECTED_URL_PARAM, SPOT_CATALOG_PATH } from 'utils/constants';
import useDataHubSelector from 'pages/Launchpad/useDataHubSelector';

export const SelectorButton = styled(Button)(({ theme }) => ({
  paddingLeft: 4,
  paddingRight: 4,
  height: 24,
  marginRight: 8,
  backgroundColor: theme.palette.text.primary + '33',

  '.MuiSvgIcon-root': {
    marginRight: 0
  },
  '.MuiTypography-root': {
    marginLeft: 4
  }
}));

const SymbolSelector = () => {
  const {
    handleDrag,
    handleClose,
    launchpadLimitReached,
    symbolSelectorState,
    position,
    dispatch,
    navigate,
    checkIfInCatalog,
    setPosition
  } = useSymbolSelector();

  const { createNewDataHub } = useDataHubSelector();
  const popperAnchorRef = useRef();
  return (
    <>
      <LimitDialog
        handleClose={handleClose}
        open={symbolSelectorState.limitReached}
      />
      {position && (
        <Draggable
          handle='div.cursor'
          bounds='body'
          position={position}
          onDrag={handleDrag}
        >
          <Box
            ref={popperAnchorRef}
            sx={{
              p: 1.5,
              alignItems: 'center',
              justifyContent: 'space-around',
              height: 46,
              backgroundColor: 'primary.main',
              zIndex: 3030,
              position: 'absolute',
              display:
                symbolSelectorState.symbols.length > 0 &&
                !symbolSelectorState.limitReached &&
                !launchpadLimitReached
                  ? 'flex'
                  : 'none',
              flexDirection: 'row'
            }}
          >
            <Box
              className='cursor'
              style={{ paddingRight: 8 }}
            >
              <Icon sx={{ cursor: 'all-scroll' }}>
                <DragIndicator />
              </Icon>
            </Box>
            <Box
              sx={{
                cursor: 'default',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <CustomCheckbox
                sx={{ cursor: 'default' }}
                checked={true}
              />
              <Box
                sx={{
                  cursor: 'default',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  px: 1,
                  mr: 1
                }}
              >
                <Typography sx={{ fontSize: 12 }}>
                  {symbolSelectorState.symbols.length} item
                  {symbolSelectorState.symbols.length > 1 && 's'} selected
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <SelectorButton
                onClick={() => {
                  dispatch(clearSelection());
                  setPosition(undefined);
                }}
              >
                <DeleteOutlined />
                <Typography variant='caption'>Clear Selection</Typography>
              </SelectorButton>
              <SelectorButton
                disabled={checkIfInCatalog()}
                onClick={() =>
                  navigate(`/catalog/${SPOT_CATALOG_PATH}?${SHOW_SELECTED_URL_PARAM}=true`)
                }
              >
                <ListAltOutlined />
                <Typography variant='caption'>View in Catalog</Typography>
              </SelectorButton>
              <SelectorButton onClick={createNewDataHub}>
                <BookmarkAddOutlined />
                <Typography variant='caption'>Create new Data Hub</Typography>
              </SelectorButton>
              <DataHubsDropdown popperAnchorRef={popperAnchorRef} />
            </Box>
          </Box>
        </Draggable>
      )}
    </>
  );
};

export default SymbolSelector;
