import { Box, Button, Link, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DefaultLayout from 'layouts/DefaultLayout';
import { getGxTokenFromStorage, getTripTokenFromStorage } from 'utils/commonFunctions';
import GoLogo from 'components/GoLogo';
import { useAppDispatch } from 'hooks/redux-hooks';
import { logout } from 'slices/authSlice';

const NotFound = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loggedIn = !!getGxTokenFromStorage() && !!getTripTokenFromStorage();

  return (
    <DefaultLayout>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          mt: '8%'
        }}
      >
        <GoLogo size={40} />
        <Typography sx={{ fontSize: 36, fontWeight: 700, mb: 2, mt: 4 }}>
          Oops! Something went wrong.
        </Typography>
        <Typography sx={{ fontSize: 24 }}>Let's get you back home</Typography>
        <Button
          variant='contained'
          sx={{
            my: 4,
            fontSize: 18,
            width: 300
          }}
          onClick={() => {
            if (!loggedIn) dispatch(logout());
            navigate(loggedIn ? '/markets' : '/login');
          }}
        >
          Go to {loggedIn ? 'Markets' : 'Login'}
        </Button>
        <Typography sx={{ fontSize: 16 }}>
          Any issues? Drop us a line at{' '}
          <Link
            href='mailto:support@general-index.com'
            target='_top'
            color='text.primary'
          >
            support@general-index.com
          </Link>
        </Typography>
      </Box>
    </DefaultLayout>
  );
};

export default NotFound;
