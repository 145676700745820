import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { useAppDispatch } from 'hooks/redux-hooks';
import { loginTrip } from 'slices/authSlice';
import { RootState } from 'store';
import { getGxTokenFromStorage } from 'utils/commonFunctions';
import { getInsightsList } from 'slices/insightsListSlice';
import { getUserConfig } from 'slices/userConfigSlice';
import { getDataHubs } from 'slices/dataHubSlice';
import ResponseMessage from 'components/ResponseMessage';

const ProtectedLayout = () => {
  const gxToken = getGxTokenFromStorage();
  const dispatch = useAppDispatch();
  const { tripToken } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (!tripToken && gxToken) {
      dispatch(loginTrip(gxToken));
    }
  }, [gxToken, tripToken]);

  useEffect(() => {
    if (tripToken) {
      dispatch(getInsightsList());
      dispatch(getUserConfig());
      dispatch(getDataHubs());
    }
  }, [tripToken]);

  if (!gxToken) {
    return (
      <Navigate
        replace
        to='/login'
      />
    );
  }
  return (
    <SnackbarProvider
      maxSnack={1}
      Components={{
        loading: ResponseMessage,
        error: ResponseMessage,
        success: ResponseMessage,
        info: ResponseMessage
      }}
    >
      <Outlet />
    </SnackbarProvider>
  );
};

export default ProtectedLayout;
