import { useEffect, useState, KeyboardEvent, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { emailValidator } from 'utils/validators';
import { useAppDispatch } from 'hooks/redux-hooks';
import { loginGX, resetStatus } from 'slices/authSlice';
import { detectEnvironment, getGxTokenFromStorage } from 'utils/commonFunctions';
import { sendHubspotInit } from 'components/Tracking/hubspotTrackingListener';
import { RootState } from 'store';

export const EMAIL_PARAM = 'userEmail';

const useLogin = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string>('');

  const [logoExpanded, setLogoExpanded] = useState<boolean>(false);
  const { status, tripToken } = useSelector((state: RootState) => state.auth);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    resetPage();
    handleEntrance();
    return () => resetPage();
  }, [tripToken]);

  const handleEntrance = () => {
    setLogoExpanded(true);
    const gxToken = getGxTokenFromStorage();

    if (gxToken) navigate('/markets');
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (getGxTokenFromStorage()) setIsLoggedIn(true);
    }, 4000);
    if (isLoggedIn) clearInterval(intervalId);
    return () => clearInterval(intervalId);
  }, [isLoggedIn, status]);

  const identifyUserInHubSpot = (email: string) => {
    if (window._hsq) {
      sendHubspotInit(window.location.pathname, email);
    }
  };

  const validateEmail = () => {
    const errorMessage = emailValidator(email);
    if (errorMessage) setError(errorMessage);
    return !errorMessage;
  };

  const handleOnKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') handleLogin();
  };

  const handleLogin = async () => {
    if (validateEmail()) {
      identifyUserInHubSpot(email);
      dispatch(loginGX(email)).then(response => {
        if (response.payload) {
          const payload = response.payload as { data: string; status: number };
          if (payload.status === 404 && detectEnvironment() === 'dev') {
            navigate(`/login/form?${EMAIL_PARAM}=${encodeURIComponent(email)}`);
          }
          if (payload.status === 403 || (detectEnvironment() !== 'dev' && payload.status === 404)) {
            setError('An error occurred. Please contact the General Index support team.'); // TODO
          }
        }
      });
    }
  };

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    if (error) setError('');
    setEmail(e.target.value);
  };

  const resetPage = () => {
    setEmail('');
    setError('');
    dispatch(resetStatus());
  };

  return {
    handleChangeEmail,
    handleOnKeyDown,
    status,
    logoExpanded,
    error,
    handleLogin,
    email,
    resetPage,
    isLoggedIn
  };
};

export default useLogin;
